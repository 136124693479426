<dp-date-picker
    priva-datepicker
    mode="day"
    alignment="left"
    data-test="day-picker"
    class="day-date-picker"
    theme="theme()"
    [placeholder]="placeholder()"
    [displayDate]="displayDate()"
    [config]="dayDatePickerConfig"
    (onChange)="changeDate($event)"
/>
