export enum TableCellId {
    Crop = 'crop',
    Crops = 'crops',
    Metric = 'metric',
}

export interface ColumnCell {
    alignment: string;
    id: string;
    metricId?: string;
    title: string;
    activeCrops?: number;
    unit?: string;
}

export interface RowCell {
    id: string;
    name?: string;
    cropId?: string;
    isCompartment?: boolean;
    metricId?: string;
    value?: number;
    hasAnomaly?: boolean;
}

export type Row = RowCell[];
export type Column = ColumnCell[];

export interface TableData {
    columnData: Column;
    rowData: Row[];
    cropIds: string[];
    metricIds: string[];
}
