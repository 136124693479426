import { Component, Input } from '@angular/core';

import { PrivaGroupModule } from '@priva/components/group';
import { PrivaTagModule } from '@priva/components/tag';

import { RowKpi } from '@app/crops';
import { NumberToLocalePipe } from '@app/utilities';

import { ValueToColorPipe } from './value-to-color.pipe';

@Component({
    selector: 'app-value-with-deviation',
    standalone: true,
    imports: [PrivaTagModule, ValueToColorPipe, PrivaGroupModule, NumberToLocalePipe],
    templateUrl: './value-with-deviation.component.html',
    styleUrl: './value-with-deviation.component.scss',
})
export class ValueWithDeviationComponent {
    @Input() item: RowKpi;
}
