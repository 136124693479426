import { createSelector } from '@ngrx/store';

import { DashboardPlanKpiConfigs } from '@app/kpis';

import { DateRange } from '../../crops-dashboard/crops-dashboard.component';
import { KpiDeviationsStateContainer } from './kpi-deviations.state.model';

export const selectCropsDeviationsState = (state: KpiDeviationsStateContainer) =>
    state.activeCropsKpiDeviations;

export const selectCropsDeviationsByRange = createSelector(selectCropsDeviationsState, (state) => {
    if (!state.cropsKpiDeviations) return undefined;

    if (state.cropKpiDeviationsRange.dateRange === DateRange.WEEKLY) return state.cropsKpiDeviations;

    const dailyKpiIds = DashboardPlanKpiConfigs.filter((kpi) => !kpi.isWeekly).map(({ id }) => id);

    return state.cropsKpiDeviations.map((kpiDeviation) => {
        const filteredDeviations = kpiDeviation.deviations.filter((dev) => dailyKpiIds.includes(dev.kpiId));
        const filteredRequestKpiIds = kpiDeviation.request.kpiIds?.filter((kpiId) =>
            dailyKpiIds.includes(kpiId),
        );

        return {
            ...kpiDeviation,
            request: {
                ...kpiDeviation.request,
                kpiIds: filteredRequestKpiIds,
            },
            deviations: filteredDeviations,
        };
    });
});

export const selectIsLoading = createSelector(selectCropsDeviationsState, (state) => state.isLoading);

export const selectCropsDeviations = createSelector(
    selectCropsDeviationsState,
    (state) => state.cropsKpiDeviations,
);
