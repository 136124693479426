import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { AnomaliesApiService } from '@app/monitoring';

import { loadCropAnomalies, loadCropAnomaliesFailure, loadCropAnomaliesSuccess } from './anomalies.actions';
import { Anomalies, AnomaliesError, AnomaliesResponse } from './anomalies.state.model';

dayjs.extend(isoWeek);

@Injectable({ providedIn: 'root' })
export class AnomaliesApiEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _anomaliesApiService = inject(AnomaliesApiService);

    /**
     * This effect will request the crop's Anomalies from the backend one crop at a time.
     * When received or when failed, the appropriate actions will be fired to deal with the result.
     */
    public loadCropAnomalies$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCropAnomalies),
            mergeMap((action) => {
                const date = action.anomaliesRequestDate;

                const observables: Observable<AnomaliesResponse>[] = action.cropIds.map((cropId) =>
                    this._anomaliesApiService
                        .getDayAnomalies(cropId, action.metricIds, date, date)
                        .pipe(catchError((error) => of({ error, cropId } as AnomaliesError))),
                );

                return forkJoin(observables).pipe(
                    map((results) => {
                        const successfulResults = results.filter(this.isSuccess);
                        const errors = results.filter(
                            (item: Anomalies) => !successfulResults.includes(item),
                        ) as AnomaliesError[];

                        if (errors.length > 0) {
                            return loadCropAnomaliesFailure({ error: errors });
                        }

                        return loadCropAnomaliesSuccess({
                            anomalies: successfulResults,
                            anomaliesRequestDate: action.anomaliesRequestDate,
                        });
                    }),
                    catchError((error) => of(loadCropAnomaliesFailure({ error }))),
                );
            }),
        );
    });

    // Type guard to determine if the response is a successful result
    private isSuccess(response: AnomaliesResponse): response is Anomalies {
        return !('error' in response);
    }
}
