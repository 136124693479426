import { Dayjs } from 'dayjs';

import { ErrorMessage } from '@priva/appshell';

import { AnomaliesRequest, Anomaly } from '@app/monitoring';

export interface AnomaliesStateContainer {
    activeCropsAnomalies: AnomaliesState;
}

export interface AnomaliesState {
    anomalies: Anomalies[];
    anomaliesRequestDate: Dayjs;
    isLoading: boolean;
}

export interface Anomalies {
    request: AnomaliesRequest;
    anomalies: Anomaly[];
}

export const initialAnomalies: AnomaliesState = {
    anomalies: undefined,
    anomaliesRequestDate: undefined,
    isLoading: false,
};

// for handling errors
export interface AnomaliesError {
    error: ErrorMessage;
    cropId: string;
}

export type AnomaliesResponse = Anomalies | AnomaliesError;
