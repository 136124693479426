import { createReducer, on } from '@ngrx/store';

import { setCropAnomalies, setCropAnomaliesIsLoading } from './anomalies.actions';
import { initialAnomalies } from './anomalies.state.model';

export const anomaliesReducer = createReducer(
    initialAnomalies,
    on(setCropAnomalies, (state, { anomalies, anomaliesRequestDate }) => ({
        ...state,
        anomalies,
        anomaliesRequestDate,
    })),
    on(setCropAnomaliesIsLoading, (state, { isLoading }) => ({
        ...state,
        isLoading,
    })),
);
