import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Dayjs } from 'dayjs';
import { EMPTY, mergeMap, withLatestFrom } from 'rxjs';

import { AnomalyMetrics } from '@app/metrics';
import { Anomaly } from '@app/monitoring';
import { compareLocalDates } from '@app/utilities';

import {
    loadAnomaliesIfNeeded,
    loadCropAnomalies,
    loadCropAnomaliesSuccess,
    setCropAnomalies,
    setCropAnomaliesIsLoading,
} from './anomalies.actions';
import { Anomalies, AnomaliesState, AnomaliesStateContainer } from './anomalies.state.model';

@Injectable({ providedIn: 'root' })
export class AnomaliesEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _store: Store<AnomaliesStateContainer> = inject(Store);

    // Helper to retrieve the current anomalies state
    private getCurrentAnomaliesState() {
        return this._store.pipe(select((state) => state.activeCropsAnomalies));
    }

    /**
     * Load anomalies if needed.
     */
    public loadAnomaliesIfNeeded$ = createEffect(() =>
        this._actions$.pipe(
            ofType(loadAnomaliesIfNeeded),
            withLatestFrom(this.getCurrentAnomaliesState()),
            mergeMap(([action, state]) => {
                const loadAnomalies = this.determineAnomaliesLoad(action, state);
                if (loadAnomalies) {
                    return [loadCropAnomalies(loadAnomalies), setCropAnomaliesIsLoading({ isLoading: true })];
                } else {
                    return EMPTY;
                }
            }),
        ),
    );

    /**
     * Handle successful anomalies load.
     */
    public loadCropAnomaliesSuccess$ = createEffect(() =>
        this._actions$.pipe(
            ofType(loadCropAnomaliesSuccess),
            withLatestFrom(this.getCurrentAnomaliesState()),
            mergeMap(([action, state]) => {
                const updatedAnomalies = this.updateAnomaliesState(action, state);
                return [setCropAnomalies(updatedAnomalies), setCropAnomaliesIsLoading({ isLoading: false })];
            }),
        ),
    );

    // Helper function to determine missing anomalies
    private determineAnomaliesLoad(
        action: ReturnType<typeof loadAnomaliesIfNeeded>,
        state: AnomaliesState,
    ): { cropIds: string[]; metricIds: string[]; anomaliesRequestDate: Dayjs } | undefined {
        if (
            state.anomalies?.length > 0 &&
            compareLocalDates(state.anomaliesRequestDate, action.anomaliesRequestDate)
        ) {
            const { missingCropIds, missingMetricIds } = this.getMissingAnomalies(state, action);

            if (missingCropIds.length > 0 || Object.keys(missingMetricIds).length > 0) {
                // Return missing cropIds and metricIds
                return {
                    cropIds: missingCropIds,
                    metricIds: Object.values(missingMetricIds).flat(),
                    anomaliesRequestDate: action.anomaliesRequestDate,
                };
            }
            return undefined;
        }

        // Return all crops and metrics if no anomalies exist in the state
        return {
            cropIds: action.cropIds,
            metricIds: action.metricIds,
            anomaliesRequestDate: action.anomaliesRequestDate,
        };
    }

    // Helper function to identify missing cropIds and metricIds
    private getMissingAnomalies(state: AnomaliesState, action: ReturnType<typeof loadAnomaliesIfNeeded>) {
        const stateMetricIds = new Map<string, Set<string>>();
        state.anomalies.forEach((anomaly) => {
            stateMetricIds.set(
                anomaly.request.cropId,
                new Set(anomaly.anomalies.map(({ metricId }) => metricId)),
            );
        });

        const stateCropIds = new Set(state.anomalies.map((anomalies) => anomalies.request.cropId));
        const missingCropIds = action.cropIds.filter((cropId) => !stateCropIds.has(cropId));

        const missingMetricIds = action.cropIds.reduce<Record<string, string[]>>((acc, cropId) => {
            const missingMetrics = action.metricIds.filter(
                (metricId) => !stateMetricIds.get(cropId)?.has(metricId),
            );
            if (missingMetrics.length > 0) {
                acc[cropId] = missingMetrics;
            }
            return acc;
        }, {});

        return { missingCropIds, missingMetricIds };
    }

    // Helper function to update the anomalies state
    private updateAnomaliesState(action: ReturnType<typeof loadCropAnomaliesSuccess>, state: AnomaliesState) {
        const existingAnomalies = structuredClone(state.anomalies || []);
        const anomaliesMetricIds = AnomalyMetrics.map(({ id }) => id);
        const metricIdOrderMap = new Map(anomaliesMetricIds.map((id, index) => [id, index]));

        action.anomalies.forEach((newAnomalies) => {
            const newAnomaliesSorted = structuredClone(newAnomalies);
            newAnomaliesSorted.anomalies.sort(
                (a, b) => metricIdOrderMap.get(a.metricId) - metricIdOrderMap.get(b.metricId),
            );
            const cropIndex = existingAnomalies.findIndex(
                (anomalies) => anomalies.request.cropId === newAnomalies.request.cropId,
            );

            if (cropIndex > -1) {
                this.updateCropAnomalies(existingAnomalies[cropIndex], newAnomaliesSorted);
            } else {
                existingAnomalies.push(newAnomaliesSorted);
            }
        });

        return { anomalies: existingAnomalies, anomaliesRequestDate: action.anomaliesRequestDate };
    }

    // Helper function to update crop anomalies
    private updateCropAnomalies(existingCrop: Anomalies, newAnomalies: Anomalies) {
        newAnomalies.anomalies.forEach((newAnomaly: Anomaly) => {
            const anomalyIndex = existingCrop.anomalies.findIndex(
                ({ metricId }: { metricId: string }) => metricId === newAnomaly.metricId,
            );

            if (anomalyIndex > -1) {
                // Replace existing metric anomaly
                existingCrop.anomalies[anomalyIndex] = newAnomaly;
            } else {
                // Add new metric anomaly
                existingCrop.anomalies.push(newAnomaly);
            }
        });
    }
}
