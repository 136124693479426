import { createSelector } from '@ngrx/store';

import { Compartment, ExtendedCrop, selectExpandedCrops } from '@priva/masterdata';
import { selectCompartments } from '@priva/masterdata';

import { selectAnomalyMetrics } from '@app/analysis';
import { MetricConfig, roundToMetricDecimals } from '@app/metrics';
import { Anomaly } from '@app/monitoring';

import { Anomalies, selectCropsAnomalies } from '../state/anomalies';
import { Column, ColumnCell, Row, RowCell, TableCellId, TableData } from './crops-anomalies.model';

// Selector to get the current crop anomalies and build the table data
export const selectTableData = createSelector(
    selectExpandedCrops,
    selectCropsAnomalies,
    selectAnomalyMetrics,
    selectCompartments,
    (
        crops: ExtendedCrop[],
        anomalies: Anomalies[],
        metrics: MetricConfig[],
        compartments: Compartment[],
    ): TableData => {
        if (!crops || anomalies.length === 0 || !metrics || !compartments) {
            return undefined;
        }

        // Create a map for quick compartment lookup
        const compartmentMap = new Map(compartments.map((comp) => [comp.id, comp.name]));

        const metricIds = metrics.map((metric) => metric.id);
        const cropIds = anomalies.map((anomaly) => anomaly.request.cropId);

        const columns = buildColumns(cropIds.length, metrics);
        const rowData = sortRowsByCropAndCompartment(
            anomalies.flatMap((anomaly) => buildRows(anomaly, crops, compartmentMap)),
            crops,
        );

        return {
            columnData: columns,
            rowData,
            cropIds,
            metricIds: metricIds,
        };
    },
);

// Helper function to build rows
function buildRows(anomalies: Anomalies, crops: ExtendedCrop[], compartmentMap: Map<string, string>): Row[] {
    const rows: Row[] = [];
    const cropId = anomalies.request.cropId;
    const cropName = crops.find((crop) => crop.id === cropId)?.name;
    const metricIds = anomalies.request.metricIds;
    const anomaliesData = anomalies.anomalies;

    // Build the crop row
    const cropRow = buildCropRow(cropId, cropName, metricIds, anomaliesData);
    rows.push(cropRow);

    // Map to store compartment rows for quick access
    const compartmentRowsMap = new Map<string, Row>();

    anomaliesData.forEach((anomaly) => {
        const { metricId } = anomaly;
        anomaly.values.forEach((value) => {
            value.compartmentValues.forEach((compartmentValue) => {
                const { id: compartmentId, value: compValue, hasAnomaly } = compartmentValue;
                let compartmentRow = compartmentRowsMap.get(compartmentId);

                if (!compartmentRow) {
                    // Initialize compartment row
                    compartmentRow = buildCompartmentRow(cropId, compartmentId, compartmentMap, metricIds);
                    compartmentRowsMap.set(compartmentId, compartmentRow);
                    rows.push(compartmentRow);
                }

                // Update the relevant metric cell
                const metricCell = compartmentRow.find(
                    (cell) => cell.metricId === metricId && cell.isCompartment,
                );
                if (metricCell) {
                    metricCell.value = roundToMetricDecimals(compValue, metricId);
                    metricCell.hasAnomaly = hasAnomaly;
                }
            });
        });
    });
    return rows;
}

// Helper function to build columns
function buildColumns(cropCount: number, metrics: MetricConfig[]): Column {
    return [
        {
            id: TableCellId.Crops,
            alignment: 'start',
            title: 'APP.CROP_ANOMALIES.CROP',
            activeCrops: cropCount,
        },
        ...metrics.map(
            (metric): ColumnCell => ({
                id: TableCellId.Metric,
                alignment: 'start',
                metricId: metric.id,
                title: metric.shortLabel,
                unit: metric.unit,
            }),
        ),
    ];
}

//Helper function to build cropRow
function buildCropRow(cropId: string, cropName: string, metricIds: string[], anomaliesData: Anomaly[]): Row {
    return [
        { cropId, name: cropName, id: TableCellId.Crop, isCompartment: false },
        ...metricIds.map((metricId): RowCell => {
            const value = anomaliesData.find((anomaly) => anomaly.metricId === metricId)?.values[0]
                ?.cropValue;
            return {
                id: TableCellId.Metric,
                cropId,
                name: metricId,
                value: value !== undefined ? roundToMetricDecimals(value, metricId) : value,
                metricId,
                hasAnomaly: false,
                isCompartment: false,
            };
        }),
    ];
}

//Helper function to build compartmentRow
function buildCompartmentRow(
    cropId: string,
    compartmentId: string,
    compartmentMap: Map<string, string>,
    metricIds: string[],
): Row {
    return [
        {
            cropId,
            name: compartmentMap.get(compartmentId),
            id: compartmentId,
            isCompartment: true,
        },
        ...metricIds.map(
            (id): RowCell => ({
                cropId,
                id: TableCellId.Metric,
                metricId: id,
                value: null,
                hasAnomaly: false,
                isCompartment: true,
            }),
        ),
    ];
}

/**
 * Sorts rows by cropName alphabetically, and then by compartmentName within each cropId group.
 */
export function sortRowsByCropAndCompartment(rows: Row[], crops: ExtendedCrop[]): Row[] {
    // Create a map for quick crop name lookup
    const cropNameMap = new Map(crops.map((crop) => [crop.id, crop.name]));

    return rows.slice().sort((rowA, rowB) => {
        const cropNameA = cropNameMap.get(rowA[0].cropId!);
        const cropNameB = cropNameMap.get(rowB[0].cropId!);

        // Sort by crop name alphabetically and numerically
        if (cropNameA !== cropNameB) {
            return cropNameA.localeCompare(cropNameB, undefined, { numeric: true });
        }

        // Within the same crop name, sort compartments alphabetically and numerically
        if (rowA[0].isCompartment && rowB[0].isCompartment) {
            const compartmentNameA = rowA[0].name;
            const compartmentNameB = rowB[0].name;
            return compartmentNameA.localeCompare(compartmentNameB, undefined, { numeric: true });
        }

        // Keep crop rows above compartment rows
        if (rowA[0].isCompartment) return 1;
        if (rowB[0].isCompartment) return -1;

        return 0; // Crop rows are equal
    });
}
