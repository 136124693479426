import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AnomaliesApiService } from '@app/monitoring';
import { nameof } from '@app/utilities';

import { AnomaliesApiEffects } from './anomalies-api.effects';
import { AnomaliesEffects } from './anomalies.effects';
import { anomaliesReducer } from './anomalies.reducer';
import { AnomaliesStateContainer } from './anomalies.state.model';

@NgModule({
    imports: [
        StoreModule.forFeature(nameof<AnomaliesStateContainer>('activeCropsAnomalies'), anomaliesReducer),
        EffectsModule.forFeature([AnomaliesEffects, AnomaliesApiEffects]),
    ],
    providers: [AnomaliesApiService],
})
export class AnomaliesStoreModule {}
