import { createAction, props } from '@ngrx/store';
import { Dayjs } from 'dayjs';

import { Anomalies, AnomaliesError } from './anomalies.state.model';

export const loadAnomaliesIfNeeded = createAction(
    '[Anomalies] Load Anomalies If Needed',
    props<{ cropIds: string[]; metricIds: string[]; anomaliesRequestDate: Dayjs }>(),
);

export const loadCropAnomalies = createAction(
    '[Anomalies] Load Anomalies',
    props<{ cropIds: string[]; metricIds: string[]; anomaliesRequestDate: Dayjs }>(),
);
export const loadCropAnomaliesSuccess = createAction(
    '[Anomalies] Load Anomalies Success',
    props<{ anomalies: Anomalies[]; anomaliesRequestDate: Dayjs }>(),
);

export const loadCropAnomaliesFailure = createAction(
    '[Anomalies] Load Anomalies Failure',
    props<{ error: AnomaliesError[] }>(),
);

export const setCropAnomalies = createAction(
    '[Anomalies] Set Anomalies',
    props<{ anomalies: Anomalies[]; anomaliesRequestDate: Dayjs }>(),
);

export const setCropAnomaliesIsLoading = createAction(
    '[Anomalies] Set Anomalies isLoading',
    props<{ isLoading: boolean }>(),
);
