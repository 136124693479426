import { Component, inject, input, OnInit, output } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import { DpDatePickerModule, ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';

import { PrivaDatepickerModule } from '@priva/components/datepicker';
import { PrivaDatepickerThemeType } from '@priva/components/datepicker/lib/datepicker.model';

import {
    DatePickerService,
    DayFormat,
    LocaleService,
    MonthFormat,
    MonthYearFormat,
    YearFormat,
} from '@app/utilities';

@Component({
    selector: 'app-crops-day-date-picker',
    standalone: true,
    imports: [DpDatePickerModule, PrivaDatepickerModule],
    templateUrl: './crops-day-date-picker.component.html',
    styleUrl: './crops-day-date-picker.component.scss',
})
export class CropsDayDatePickerComponent implements OnInit {
    private readonly datePickerService = inject(DatePickerService);
    private readonly _localeService = inject(LocaleService);
    public dayDatePickerConfig: IDatePickerConfig;
    public minDate = input.required<Dayjs>();
    public maxDate = input.required<Dayjs>();
    public placeholder = input.required<string>();
    public displayDate = input.required<Dayjs>();
    public theme = input<PrivaDatepickerThemeType>('onlightgray');

    public onChange = output<Dayjs | Dayjs[]>();

    constructor() {
        dayjs.locale(this._localeService.language);
    }

    public ngOnInit(): void {
        this.getDayDatePickerConfig();
    }

    public changeDate(date: Dayjs): void {
        this.onChange.emit(date);
    }

    private getDayDatePickerConfig(): void {
        const minDate = this.minDate();
        const maxDate = this.maxDate();
        this.dayDatePickerConfig = this.datePickerService.createConfig({
            dayBtnFormatter: (day: dayjs.Dayjs) => day.format(DayFormat),
            monthFormatter: (day: dayjs.Dayjs) => day.format(MonthYearFormat),
            monthBtnFormatter: (day: dayjs.Dayjs) => day.format(MonthFormat),
            yearFormatter: (day: dayjs.Dayjs) => day.format(YearFormat),
            returnedValueType: ECalendarValue.Dayjs,
            min: minDate,
            max: maxDate,
            locale: dayjs.locale(),
        } as IDatePickerConfig);
    }
}
