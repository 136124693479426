<div class="value-with-deviation">
    @if (item.isOutOfBand) {
        <priva-group spacing="none" class="value-with-deviation__tag">
            <priva-tag
                size="sm"
                density="md"
                variant="flat"
                split="end"
                [category]="item.deviation | valueToColor"
                [isRemovable]="false"
            >
                {{ (item.value | numbertolocale) ?? '-' }}
            </priva-tag>
            <priva-tag
                class="flex-shrink-0"
                size="sm"
                density="sm"
                variant="solid"
                split="start"
                [category]="item.deviation | valueToColor"
                [isRemovable]="false"
            >
                <span class="font-size-xs"
                    >{{ item.deviation > 0 ? '+' : '' }}{{ item.deviation | numbertolocale }}</span
                >
            </priva-tag>
        </priva-group>
    } @else {
        <span class="value-with-deviation__value" [class.target-not-set]="!item.hasTarget">{{
            (item.value | numbertolocale) || '-'
        }}</span>
    }
</div>
